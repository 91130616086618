// extracted by mini-css-extract-plugin
export var shopHeader = "_shop-module--shopHeader--ywcU-";
export var shopPageTtl = "_shop-module--shopPageTtl--ni04S";
export var itemListNum = "_shop-module--itemListNum--SCTKK";
export var caution = "_shop-module--caution--vbr6t";
export var notes2 = "_shop-module--notes2--d3sj6";
export var productWrapper = "_shop-module--productWrapper--u5zII";
export var productSection1 = "_shop-module--productSection1--FrFkU";
export var productSection2 = "_shop-module--productSection2--EZjLv";
export var productName = "_shop-module--productName--NJhlM";
export var price = "_shop-module--price--eYJT8";
export var tax = "_shop-module--tax--g45VZ";
export var shippingFee = "_shop-module--shippingFee--DW4pH";
export var addCartWrapper = "_shop-module--addCartWrapper--HzEv6";
export var addCart = "_shop-module--addCart--DwUek";
export var shopBtnWrapper = "_shop-module--shopBtnWrapper--Nq1mr";
export var backBtn = "_shop-module--backBtn--BTJVu";
export var nextBtn = "_shop-module--nextBtn--S3f5E";
export var confTtl = "_shop-module--confTtl--Ohd3F";
export var orderDetailWrapper = "_shop-module--orderDetailWrapper--ajaJR";
export var orderDetail = "_shop-module--orderDetail--pbIaF";