import * as React from "react"
import { useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import { useForm } from "react-hook-form";

import * as styles from "src/scss/_shop.module.scss"
import * as customerStyles from "src/scss/_customerForm.module.scss"

export default function Cart () {
  useEffect(() => {
    const link = document.referrer;
    if (link.match(/check-out/)) {
      const customerData = JSON.parse(localStorage.getItem("customerInfoObj"))

      if (customerData !== null) {
        setValue('name', customerData.name)
        setValue('nameKana', customerData.nameKana)

        if (customerData.company !== null) {
          setValue('company', customerData.company)
        }

        if (customerData.companyKana !== null) {
          setValue('companyKana', customerData.companyKana)
        }

        setValue('zip', customerData.zip)
        setValue('state', customerData.state)
        setValue('address1', customerData.address1)

        if (customerData.address2 !== null) {
          setValue('address2', customerData.address2)
        }

        setValue('mail', customerData.mail)
        setValue('tel', customerData.tel)

        if (customerData.contactTel !== null) {
          setValue('contactTel', customerData.contactTel)
        }

        if (customerData.fax !== null) {
          setValue('fax', customerData.fax)
        }

        if (customerData.message !== null) {
          setValue('message', customerData.message)
        }

        if (customerData.privacy === true) {
          setValue('privacy', customerData.privacy)
        }
      }
    }
  }, []);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm(
    {
      reValidateMode: 'onSubmit',
    }
  );

  const onSubmit = data => { // フォームを送信する処理
    localStorage.setItem("customerInfoObj", JSON.stringify(data));
    window.location.href = '/online-store/check-out/';
  };

  const zipValue = watch("zip")
  if (zipValue !== undefined) {
    let zipRelace = null
    const hyphen = zipValue.replace(/[‐－―ー]/g, '-');
    zipRelace = hyphen.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('zip').value = zipRelace
  }

  const mailValue = watch("mail")
  if (mailValue !== undefined) { // メールを全角から半角に変更
    let mailReplace = null
    mailReplace = mailValue.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('mail').value = mailReplace
  }


  const telValue = watch("tel")
  if (telValue !== undefined) {
    let telReplace = null
    const hyphen = telValue.replace(/[‐－―ー]/g, ''); // 全角ハイフンをとる
    const hyphen2 = hyphen.replace(/[\-\x20]/g, ''); // 半角ハイフン、スペースをとる
    telReplace = hyphen2.replace(/[０-９]/g, function(s) {  // 数字を全角から半角に変更
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('tel').value = telReplace
  }

  const contactTelValue = watch("contactTel")
  if (contactTelValue !== undefined) { // 連絡先電話番号を全角から半角に変更
    let contactTelReplace = null
    const hyphen = contactTelValue.replace(/[‐－―ー]/g, '-');
    const hyphen2 = hyphen.replace(/[\-\x20]/g, '');
    contactTelReplace = hyphen2.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('contactTel').value = contactTelReplace
  }

  const faxValue = watch("fax")
  if (faxValue !== undefined) { // FAX番号を全角から半角に変更
    let faxReplace = null
    const hyphen = faxValue.replace(/[‐－―ー]/g, '-');
    const hyphen2 = hyphen.replace(/[\-\x20]/g, '');
    faxReplace = hyphen2.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    document.getElementById('fax').value = faxReplace
  }

  return (
    <Layout>
      <Seo title="お客様・お届け先登録" />
      <div className={styles.shopHeader}>
        <h1>ReNK CHANNEL オンラインショッピング</h1>
      </div>
      <div className="pageWrapper">
        <h2 className={styles.shopPageTtl}>お客様・お届け先登録</h2>

        <form onSubmit={handleSubmit(onSubmit)} className={customerStyles.customerInfo}>
          <table>
            <thead>
              <tr>
                <td>
                  <label htmlFor="name">お名前<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：山田 太郎</p>
                </td>
                <td>
                  <input type="text" placeholder="例：山田 太郎" name="name" id="name" {...register('name', { required: true, maxLength: 30 })} />
                  <p className={customerStyles.errMsg}>
                    {errors.name && errors.name.type === "required" &&"名前を入力してください"}
                    {errors.name && errors.name.type === "maxLength" &&"名前が長すぎます"}
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="nameKana">お名前（カタカナ）<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：ヤマダ タロウ</p>
                </td>
                <td>
                  <input type="text" placeholder="例：ヤマダ タロウ" name="nameKana" id="nameKana" {...register('nameKana', { required: true, maxLength: 30 })} />
                  <p className={customerStyles.errMsg}>
                    {errors.nameKana && errors.nameKana.type === "required" &&"フリガナを入力してください"}
                    {errors.nameKana && errors.nameKana.type === "maxLength" &&"フリガナが長すぎます"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="company">会社名</label>
                  <p className={customerStyles.inputEx}>例：リンクチャネル株式会社</p>
                </td>
                <td>
                  <input type="text" placeholder="例：リンクチャネル株式会社" name="company" id="company" {...register('company', { maxLength: 40 })} />
                  <p className={customerStyles.errMsg2}>
                    {errors.company&&"文字数が長すぎます"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="companyKana">会社名（カタカナ）</label>
                  <p className={customerStyles.inputEx}>例：リンクチャネル カブシキガイシャ</p>
                </td>
                <td>
                  <input type="text" placeholder="例：リンクチャネル カブシキガイシャ" name="companyKana" id="companyKana" {...register('companyKana', { maxLength: 40 })} />
                  <p className={customerStyles.errMsg2}>
                    {errors.companyKana&&"文字数が長すぎます"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="zip">郵便番号<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：950-1111</p>
                </td>
                <td>
                  <input type="text" placeholder="例：950-1111" name="zip" id="zip" {...register('zip', { required: true, pattern: /^[0-9]{3}-?[0-9]{4}$/ })} />
                  <p className={customerStyles.errMsg}>
                    {errors.zip && errors.zip.type === "required" &&"郵便番号を入力してください"}
                    {errors.zip && errors.zip.type === "pattern" &&"正しい形でご入力ください"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>都道府県<span>必須</span></p>
                </td>
                <td>
                  <select id="state" name="state" defaultValue={'東京都'} {...register('state')} >
                    <option value="北海道">北海道</option>
                    <option value="青森県">青森県</option>
                    <option value="岩手県">岩手県</option>
                    <option value="宮城県">宮城県</option>
                    <option value="秋田県">秋田県</option>
                    <option value="山形県">山形県</option>
                    <option value="福島県">福島県</option>
                    <option value="茨城県">茨城県</option>
                    <option value="栃木県">栃木県</option>
                    <option value="群馬県">群馬県</option>
                    <option value="埼玉県">埼玉県</option>
                    <option value="千葉県">千葉県</option>
                    <option value="東京都">東京都</option>
                    <option value="神奈川県">神奈川県</option>
                    <option value="新潟県">新潟県</option>
                    <option value="富山県">富山県</option>
                    <option value="石川県">石川県</option>
                    <option value="福井県">福井県</option>
                    <option value="山梨県">山梨県</option>
                    <option value="長野県">長野県</option>
                    <option value="岐阜県">岐阜県</option>
                    <option value="静岡県">静岡県</option>
                    <option value="愛知県">愛知県</option>
                    <option value="三重県">三重県</option>
                    <option value="滋賀県">滋賀県</option>
                    <option value="京都府">京都府</option>
                    <option value="大阪府">大阪府</option>
                    <option value="兵庫県">兵庫県</option>
                    <option value="奈良県">奈良県</option>
                    <option value="和歌山県">和歌山県</option>
                    <option value="鳥取県">鳥取県</option>
                    <option value="島根県">島根県</option>
                    <option value="岡山県">岡山県</option>
                    <option value="広島県">広島県</option>
                    <option value="山口県">山口県</option>
                    <option value="徳島県">徳島県</option>
                    <option value="香川県">香川県</option>
                    <option value="愛媛県">愛媛県</option>
                    <option value="高知県">高知県</option>
                    <option value="福岡県">福岡県</option>
                    <option value="佐賀県">佐賀県</option>
                    <option value="長崎県">長崎県</option>
                    <option value="熊本県">熊本県</option>
                    <option value="大分県">大分県</option>
                    <option value="宮崎県">宮崎県</option>
                    <option value="鹿児島県">鹿児島県</option>
                    <option value="沖縄県">沖縄県</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="address1">市区町村番地<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：新潟市中央区笹口00-00</p>
                </td>
                <td>
                  <input type="text" placeholder="例：新潟市中央区笹口00-00" name="address1" id="address1" {...register('address1', { maxLength: 50 })} />
                  <p className={customerStyles.errMsg2}>
                    {errors.address1&&"文字数が長すぎます"}
                  </p>
                </td>
              </tr>
              <tr>
                <td><label htmlFor="address2">アパート・マンション</label></td>
                <td><input type="text" name="address2" id="address2" {...register('address2')} /></td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="mail">メールアドレス<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：yamada@email.jp 半角</p>
                </td>
                <td>
                  <input type="mail" placeholder="例：yamada@email.jp" name="mail" id="mail" {...register('mail', { required: true, pattern: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/ })} />
                  <p className={customerStyles.errMsg}>
                    {errors.mail && errors.mail.type === "required" &&"メールアドレスを入力してください"}
                    {errors.mail && errors.mail.type === "pattern" &&"正しい形でご入力ください"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="tel">電話番号<span>必須</span></label>
                  <p className={customerStyles.inputEx}>例：0251234567  半角、ハイフン(-)なし</p>
                </td>
                <td>
                  <input type="tel" placeholder="例：09011112222（ハイフン-なし）" name="tel" id="tel" {...register('tel', {required: true, pattern: /^(0{1}\d{9,10})$/ })} />
                  <p className={customerStyles.errMsg}>
                    {errors.tel && errors.tel.type === "required" &&"電話番号を入力してください"}
                    {errors.tel && errors.tel.type === "pattern" &&"正しい形でご入力ください"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="contactTel">連絡先電話番号</label>
                  <p className={customerStyles.inputEx}>例：0251234567  半角、ハイフン(-)なし</p>
                </td>
                <td>
                  <input type="tel" placeholder="例：09011112222（ハイフン-なし）" name="contactTel" id="contactTel" {...register('contactTel', {pattern: /^(0{1}\d{9,10})$/ })} />
                  <p className={customerStyles.errMsg}>
                    {errors.contactTel&&"正しい形でご入力ください"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="fax">FAX番号</label>
                  <p className={customerStyles.inputEx}>例：0251234567  半角、ハイフン(-)なし</p>
                </td>
                <td>
                  <input type="tel" placeholder="例：09011112222（ハイフン-なし）" name="fax" id="fax" {...register('fax', {pattern: /^(0{1}\d{9,10})$/ })} />
                  <p className={customerStyles.errMsg}>
                    {errors.fax&&"正しい形でご入力ください"}
                  </p>
                </td>
              </tr>
              <tr>
                <td><label htmlFor="message">備考</label></td>
                <td><textarea name="message" id="message" rows="2" {...register('message')} /></td>
              </tr>
              <tr>
                <td>
                  個人情報の取り扱いについて
                </td>
                <td>
                  <div className="policy">
                    <p>プライバシーポリシー 【基本方針】</p>
                    <p>株式会社ハードオフコーポレーションおよび子会社（以下「当社グループ」）は、お客様、お取引様、
                      株主様ならびに当社グループ役員および社員の個人情報保護の重要性を認識し、
                      法令遵守および安全に取扱いするため、以下のとおり取り組んでまいります。</p>
                    <p>【個人情報保護に関する事項】</p>
                    <ol>
                      <li className="listTtl">法令等の遵守</li>
                      <p>
                        当社グループは、個人情報を取扱うにあたり、個人情報保護法その他関係法令および 業界ガイドライン、社内規程等を遵守いたします。
                      </p>

                      <li className="listTtl">個人情報の利用について</li>
                      <p>当社グループにおける個人情報は、個人情報の提供を受ける際にお知らせする利用目的の範囲内で利用します。</p>

                      <li className="listTtl">個人情報の安全管理について</li>
                      <p>当社グループは、個人情報を適正に管理・運用するために個人情報保護規程および特定個人情報取扱規程を定め、
                        すべての役員・社員に対し、個人情報の安全管理が図られるよう適切な監督を行います。<br />
                        また、個人情報を委託する場合、個人情報の安全管理が図られるよう委託先に対し必要かつ適切な監督を行います。
                      </p>

                      <li className="listTtl">個人情報の第三者への提供について</li>
                      <p>当社グループは、次の場合を除き個人情報を第三者へ開示・提供いたしません。</p>
                      <ol>
                        <li>本人の同意がある場合</li>
                        <li>法令に基づく場合</li>
                        <li>人の生命、身体または財産の保護のために第三者提供が必要な場合で、本人の同意を得ることが困難なとき</li>
                        <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要な場合で、本人の同意を得ることが困難なとき</li>
                        <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、
                          本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                        </li>
                      </ol>

                      <li className="listTtl">共同利用について</li>
                      <p>当社グル―プは、個人情報を共同利用する場合があります。</p>
                      <ol>
                        <li>共同して利用する個人データの項目 氏名、住所、年齢、生年月日、職業、電話番号、商品売買情報等</li>
                        <li>共同して利用する者の範囲 当社グループ各社およびフランチャイズ加盟店</li>
                        <li>利用する者の利用目的 個人情報の提供を受ける際にお知らせする利用目的</li>
                        <li>当該個人データの管理について責任を有する者の名称 株式会社ハードオフコーポレーション</li>
                      </ol>

                      <li className="listTtl">個人情報の開示・訂正・利用停止について</li>
                      <p>当社グループは、保有している個人情報の開示・訂正・利用停止に関するお問い合わせについて合理的な範囲で速やかに対応いたします
                        （なお一定の制約をともなう場合や請求者等の費用負担が必要になる場合があります）。
                      </p>
                    </ol>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className={customerStyles.agreement}>
                  <input type="checkbox" name="privacy" id="privacy" {...register('privacy', { required: true })} />
                  <label htmlFor="privacy">個人情報保護方針に同意する</label>
                  <p className={customerStyles.errMsg2}>
                    {errors.privacy&&"個人情報保護方針にご同意ください。同意するにはチェックを入れます。"}
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className={styles.shopBtnWrapper}>
                    <div className={styles.backBtn}>
                      <a href="/online-store/cart/">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"><rect fill="none" height="30" width="30"/><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z"/></svg>
                        カゴへ戻る
                      </a>
                    </div>

                    <button className={styles.nextBtn} type="submit">
                      確認へ進む
                      <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </form>
        <div className="recaptcha">
          This site is protected by reCAPTCHA and the Google <br />
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> apply.
        </div>
      </div>
    </Layout>
  )
}
